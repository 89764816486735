<template>
  <div class="healthDegree-fragment">
    <div class="fragment-title">
      <div class="title-warp">
        <img src="@/assets/images/fragment-title-icon.png" />
        <div class="title">历史健康度趋势</div>
      </div>
      <div class="level">
        <div @click="changeLevel(index)" v-for="(item, index) in levelList" :key="index" class="level-item" :class="{ 'activi-level': index === levelIndex }">{{ item }}</div>
      </div>
    </div>
    <div class="fragment-content">
      <div class="item-warp">
        <div id="trendHealthChart" class="trendHealthChart"></div>
      </div>
      <div class="item-warp">
        <div class="entirety-warp">
          <div class="title1">整体健康度</div>
          <div id="entiretyHealthChart" class="entiretyHealthChart"></div>
          <div class="month-warp">
            <div v-for="(item, index) in entiretyHealthMonthArrays" class="month-item" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="slide-item-warp" ref="scrollContainer">
        <div class="left-slide-btn" @click="scrollLeft" v-if="componentChartList && componentChartList.length > 4">
          <i :style="{ color: isAtLeftEdge ? '#999' : '#87B9E8' }" class="el-icon-arrow-left"></i>
        </div>

        <div class="component-warp" ref="componentWarp" :class="{ 'scroll-transition': isTransitioning }" @scroll="checkScrollPosition"> 
          <div class="component-item" v-for="(item, index) in componentChartList" :key="index" ref="componentItem">
            <div class="title1">{{ item.systemName }}</div>
            <div :id="'componentChart' + index" :class="isAllScroll ? 'componentChart' : 'componentChart'"></div>
            <div class="month-warp month-warp-componentChart">
              <div v-for="(item, index) in componentChartMonthArr" style="margin-bottom: 6px;" class="month-item" :key="index">{{ item }}</div>
            </div>
          </div>
        </div>
        <div class="right-slide-btn" @click="scrollRight" v-if="componentChartList && componentChartList.length > 4">
          <i :style="{ color: isAtRightEdge ? '#999' : '#87B9E8' }" class="el-icon-arrow-right"></i>
        </div>
      </div>
    </div>
  </div>
</template>
        
        <script>
import echarts from "echarts";
import { assessmentHistoryChartOption, assessmentWholeChartOption, assessmentComponentChartOption } from "./echartOptions.js";
import moment from "moment";
export default {
  props: ["h"],
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),

      levelIndex: 0,
      levelList: ["一级", "二级", "三级"],

      componentChartList: [],
      isTransitioning: false,

      isAtLeftEdge: true,
      isAtRightEdge: false,
      entiretyHealthMonthArrays: [],
      trendHealthChart: undefined,
      entiretyHealthChart: undefined,
      componentChart: undefined,
      componentChartArr: [],
      isAllScroll: false,
      componentChartMonthArr: [],
    };
  },
  created() {
    this.setTableHeight();
  },
  mounted() {
    //历史健康度趋势
    this.getAssessmentHistory();
    //整体健康度折线
    this.getAssessmentWholeChart();
    //构件健康度折线
    this.getComponentChart();
  },
  methods: {
    changeLevel(index) {
      this.levelIndex = index;
      this.getAssessmentHistory();
    },
    getAssessmentHistory() {
      this.trendHealthChart = echarts.init(document.getElementById("trendHealthChart"));
      var option = assessmentHistoryChartOption;
      const params = {
        projectId: this.projectId,
        level: this.levelIndex + 1,
      };
      this.$axios.get(`${this.baseURL}health/assessment/history`, { params }).then((res) => {
        var resData = res.data.data;
        var timeData = [];
        var monitoringData = [];
        var analysisResult = [];
        let componentResult = [];
        resData.forEach((item) => {
          timeData.push(moment(item.timeO).locale("zh-cn").format("M月"));
          monitoringData.push(item.monitorData);
          analysisResult.push(item.analysisResult);
          componentResult.push(item.componentResult);
        });
        var isOverMax = false;
        monitoringData.forEach((item) => {
          if (item > 4) {
            isOverMax = true;
          }
        });
        analysisResult.forEach((item) => {
          if (item > 4) {
            isOverMax = true;
          }
        });
        componentResult.forEach((item) => {
          if (item > 4) {
            isOverMax = true;
          }
        });
        if (isOverMax) {
          option.yAxis.max = null;
        }
        option.xAxis.data = timeData;
        option.series[0].data = monitoringData;
        option.series[1].data = analysisResult;
        option.series[2].data = componentResult;
        option.tooltip.formatter = function (params) {
          var dataItem = resData[params.dataIndex]
          return"监测数据: " + dataItem.monitorData + "<br/>分析结果: " +dataItem.analysisResult+ "<br/>构件评估: " +dataItem.componentResult;
        };

        this.trendHealthChart.setOption(option);
      });
    },
    getAssessmentWholeChart() {
      var option = assessmentWholeChartOption;
      this.entiretyHealthChart = echarts.init(document.getElementById("entiretyHealthChart"));
      var timeValueData = [];
      var stateValueData = [];
      const params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/wholeChart`, { params }).then((res) => {
        var resData = res.data.data;
        // 初始化空数组
        const timeOArray = [];
        const scoreArray = [];
        const monthArrays = [];
        // 遍历数据
        for (const key in resData) {
          if (resData.hasOwnProperty(key)) {
            monthArrays.push(moment(key).format("M月"));
            const monthData = resData[key];
            monthData.forEach((item) => {
              timeValueData.push(item.timeO || key);
              stateValueData.push(item.state);
              // timeOArray.push(moment(item.timeO).locale("zh-cn").format("M月"));
              timeOArray.push(item.timeO);
              scoreArray.push([new Date(item.timeO || key + "-01").getTime(), item.score]);
            });
          }
        }
        this.entiretyHealthMonthArrays = monthArrays;
        option.tooltip.formatter = function (params) {
          let data = ["I基本完好", "II轻微异常", "III中等异常", "IV严重异常"];
          return "时间: " + timeValueData[params.dataIndex] + "<br/>健康度: " + data[stateValueData[params.dataIndex]];
        };
        option.series[0].data = scoreArray;
        this.entiretyHealthChart.setOption(option);
      });
    },
    getComponentChart() {
      const params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/componentChart`, { params }).then((res) => {
        var resData = res.data.data;
        this.componentChartList = resData;
        this.$nextTick(() => {
          this.initComponentChart();
        });
      });
    },
    initComponentChart() {
      var option = assessmentComponentChartOption;

      this.componentChartList.forEach((item, index) => {
        var componentChart = echarts.init(document.getElementById("componentChart" + index));
        this.componentChartArr.push(componentChart);
        var timeData = [];
        var levelData = [];

        const timeOArray = [];
        const scoreArray = [];
        const monthArrays = [];
        var timeValueData = [];
        var stateValueData = [];

        for (const key in item.months) {
          if (item.months.hasOwnProperty(key)) {
            monthArrays.push(moment(key).format("M月"));
            const monthData = item.months[key];
            monthData.forEach((item) => {
              timeValueData.push(item.timeO || key);
              var state = item.score == 0 ? 3 : item.score == 1 ? 2 : item.score == 2 ? 1 : item.score == 3 ? 0 : -1;
              stateValueData.push(state);
              timeOArray.push(item.timeO);
              scoreArray.push([new Date(item.timeO || key + "-01").getTime(), item.score]);
            });
          }
        }
        this.componentChartMonthArr = [];
        monthArrays.forEach((i, index) => {
          if (index === 0 || index === 3 || index === 7 || index === 11) {
            this.componentChartMonthArr.push(i);
          }
        });
        // item.voLists.forEach((voItem) => {
        //   timeData.push(moment(voItem.timeO).locale("zh-cn").format("M月"));
        //   timeValueData.push(voItem.timeO);
        //   levelData.push(voItem.score);
        // });
        option.tooltip.formatter = function (params) {
          let data = ["I基本完好", "II轻微异常", "III中等异常", "IV严重异常"];
          return "时间: " + timeValueData[params.dataIndex] + "<br/>健康度: " + data[stateValueData[params.dataIndex]];
        };
        // option.xAxis.data = timeData;
        option.series[0].data = scoreArray;
        componentChart.setOption(option);
      });
    },
    scrollLeft() {
      let itemW = this.$refs['componentItem'][0].offsetWidth // 当前项宽度
      let lW = this.$refs['componentWarp'].scrollLeft // 已滚动数量
      this.isTransitioning = true;
      this.$refs["componentWarp"].scrollLeft -= itemW;
      this.setTransitionState();
    },
    scrollRight() {
      let itemW = this.$refs['componentItem'][0].offsetWidth // 当前项宽度
      let lW = this.$refs['componentWarp'].scrollLeft // 已滚动数量
      if((this.$refs['componentWarp'].scrollWidth - this.$refs['componentWarp'].clientWidth - lW) < 20){
        return
      }
      this.isTransitioning = true;
      this.$refs["componentWarp"].scrollLeft += itemW;
      this.setTransitionState();
    },
    setTransitionState() {
      setTimeout(() => {
        this.isTransitioning = false;
      }, 500); // 调整动画持续时间
    },
    checkScrollPosition() {
      const container = this.$refs["scrollContainer"];
      const content = this.$refs["componentWarp"];

      this.isAtLeftEdge = content.scrollLeft === 0;
      this.isAtRightEdge = content.scrollLeft + container.clientWidth >= content.scrollWidth;
    },
    setTableHeight() {
      /*     this.trendHealthChart&&this.trendHealthChart.resize();
      this.entiretyHealthChart&&this.entiretyHealthChart.resize();
      var option = assessmentComponentChartOption;
      this.componentChartArr.forEach((item, index) => {
        // if(this.isAllScroll){
        //   option.grid.bottom = -60
        // }else{
        //   option.grid.bottom = 25
        // }
        item.setOption(option);
        item&&item.resize();
      }); */
      // this.componentChart&&this.componentChart.resize();
    },
  },
  watch: {
    h(newVal, oldVal) {
      if (newVal >= oldVal) {
        this.isAllScroll = true;
      } else {
        this.isAllScroll = false;
      }
      this.setTableHeight();
      // this.componentChartArr = []
      // this.getComponentChart()
    },
  },
};
</script>
        
        <style lang="scss" scoped>
.healthDegree-fragment {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  .fragment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    padding-bottom: 0px;
    .title-warp {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
      .title {
        color: #ffffff;
        font-size: 16px;
      }
    }
    .level {
      display: flex;
      font-size: 14px;
      .level-item {
        padding: 0 20px;
        text-align: center;
        color: #ccdaff;
        border-right: 1px solid #3a4a7b;
        cursor: pointer;
      }
      .level-item:last-child {
        border-right: none;
        padding-right: 0;
      }
      .activi-level {
        position: relative;
        font-weight: 600;
        color: #ffffff;
      }
      .activi-level::after {
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 35px;
        height: 4px;
        bottom: -8px;
        background-image: linear-gradient(90deg, #00135a00 0%, #1e87ff 54%, #00135b00 100%);
      }
      .activi-level:last-child:after {
        content: "";
        display: block;
        position: absolute;
        left: calc(50% + 8px);
        transform: translateX(-50%);
        width: 35px;
        height: 4px;
        bottom: -8px;
        background-image: linear-gradient(90deg, #00135a00 0%, #1e87ff 54%, #00135b00 100%);
      }
    }
  }

  .fragment-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    // padding-right: 20px;
    > div {
      flex: 1;
    }
    .item-warp {
      width: 100%;
      display: flex;
      padding-left: 50px;
      position: relative;
      padding-right: 20px;
      .entirety-warp {
        width: 100%;
      }
      .title {
        position: relative;
        font-size: 14px;
        color: #ffffff;
        span {
          display: inline-block;
          font-size: 14px;
          margin-left: 24px;
        }
      }
      .title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -22px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
      .title1 {
        position: relative;
        font-size: 14px;
        color: #ffffff;
      }
      .title1::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
      .trendHealthChart {
        width: 100%;
        /* height: 134px; */
        height: 100%;
      }
      .entiretyHealthChart {
        width: 100%;
        height: 120px;
      }
    }
    .slide-item-warp {
      position: relative;
      padding-left: 30px;
      .left-slide-btn {
        position: absolute;
        left: 30px;
        height: 60px;
        width: 16px;
        top: 40px;
        background: #1b346e;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
      }
      .right-slide-btn {
        position: absolute;
        right: 0px;
        height: 60px;
        width: 16px;
        top: 40px;
        background: #1b346e;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .component-warp {
        display: flex;
        width: 100%;
        overflow-x: hidden;
        overflow-y: hidden;
        padding-left: 20px;
        scroll-behavior: smooth;
      }
      .component-item {
        min-width: 25.3%;
        margin-top: 5px;
      }

      .title1 {
        position: relative;
        font-size: 14px;
        color: #ffffff;
      }
      .title1::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -12px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
      .componentChart {
        width: 100%;
        height: 90px;
      }
      .componentChart1 {
        width: 100%;
        height: 140px;
      }
    }
  }
  .scroll-transition {
    transition: scroll-behavior 0.5s ease-in-out; /* 调整动画持续时间和缓动函数 */
  }
}
.month-warp {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-left: 20px;
  padding-right: 90px;
  font-size: 15px;
  .month-item {
    color: #a7c8fc;
  }
}
.month-warp-componentChart {
  padding-left: 90px;
  padding-right: 30px;
  font-size: 13px;
}
</style>
        