<template>
  <div class="healthDegree-fragment">
    <div class="fragment-title">
      <div class="title-warp">
        <img src="@/assets/images/fragment-title-icon.png" />
        <div class="title">健康度评估</div>
      </div>
      <!-- <div class="setting">
        <img src="@/assets/images/setting-icon.png" />
        <div class="title">设置</div>
      </div> -->
    </div>
    <div class="fragment-content">
      <div class="left-container" v-if="finalState != -1">
        <div class="title">
          整体健康度 <span :style="{ color: finalStateColor[finalState] }" style="font-weight: 600">{{ finalStateText[finalState] }}</span>
        </div>
        <div class="echat-warp">
          <div class="left-warp">
            <img :src="finalStateImg[finalState]" />
          </div>
          <div class="right-warp">
            <div id="overallHealthChart" class="overallHealthChart"></div>
          </div>
        </div>
      </div>
      <div class="division"></div>
      <div class="right-container">
        <div class="title">构件健康度</div>
        <div class="echat-warp">
          <div class="left-warp" ref="scrollContainer">
            <div class="right-slide-warp" v-if="componentData && componentData.length > 5">
              <div class="top-slide-btn" @click="scrollTop">
                <i :style="{ color: isAtTopEdge ? '#999' : '#87B9E8' }" class="el-icon-arrow-left"></i>
              </div>
              <div class="bottom-slide-btn" @click="scrollBottom">
                <i :style="{ color: isAtBottomEdge ? '#999' : '#87B9E8' }" class="el-icon-arrow-left"></i>
              </div>
            </div>

            <div class="component-warp" ref="componentWarp" :class="{ 'scroll-transition': isTransitioning }" @scroll="checkScrollPosition">
              <div class="item-component" v-for="(item, index) in componentData" :key="index">
                <div class="item-title">{{ item.systemName }}</div>
                <div class="item-value" :style="{ color: finalStateColor[item.state] }">{{ finalStateText[item.state] }}</div>
              </div>
            </div>
          </div>
          <div class="right-warp">
            <div id="componentHealthChart" class="componentHealthChart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
      
      <script>
import echarts from "echarts";
import { overallHealthChartOption, componentHealthChartOption } from "./echartOptions.js";
export default {
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      finalState: -1,
      finalStateImg: [
        require("@/assets/images/healthDegree/1lei.png"),
        require("@/assets/images/healthDegree/2lei.png"),
        require("@/assets/images/healthDegree/3lei.png"),
        require("@/assets/images/healthDegree/4lei.png"),
      ],
      finalStateText: ["Ⅰ基本完好", "Ⅱ轻微异常", "Ⅲ中等异常", "Ⅳ严重异常"],
      finalStateColor: ["#6AEE79", "#4281E8", "#F38F42", "#E74E4F"],

      componentData: [],
      isTransitioning: false,

      isAtTopEdge: true,
      isAtBottomEdge: false,
    };
  },
  created() {},
  mounted() {
    //整体健康度
    this.getSssessmentWhole();
    //构件健康度
    this.getSssessmentComponent();
  },
  methods: {
    scrollTop() {
      this.isTransitioning = true;
      this.$refs["componentWarp"].scrollTop -= 84;
      this.setTransitionState();
    },
    scrollBottom() {
      this.isTransitioning = true;
      this.$refs["componentWarp"].scrollTop += 84;
      this.setTransitionState();
    },
    setTransitionState() {
      setTimeout(() => {
        this.isTransitioning = false;
      }, 500); // 调整动画持续时间
    },
    checkScrollPosition() {
      const container = this.$refs["scrollContainer"];
      const content = this.$refs["componentWarp"];

      this.isAtTopEdge = content.scrollTop === 0;
      this.isAtBottomEdge = content.scrollTop + container.clientHeight >= content.scrollHeight;
    },
    getSssessmentWhole() {
      var option = overallHealthChartOption;
      var params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/whole`, { params }).then((res) => {
        if (res.data.status == 200) {
          const resData = res.data.data;
          var jianceData = resData[0];
          var fenxiData = resData[1];
          var goujianData = resData[2];
          option.series[0].data = [goujianData.firstCount,fenxiData.firstCount,jianceData.firstCount ];
          option.series[1].data = [goujianData.secondCount,fenxiData.secondCount,jianceData.secondCount];
          option.series[2].data = [goujianData.thirdCount,fenxiData.thirdCount,jianceData.thirdCount];
          // option.series[3].data = [goujianData.middleCount, 0, 0];
          // option.series[4].data = [goujianData.seriousCount, 0, 0];
          if (
            fenxiData.firstCount > 5 ||
            fenxiData.secondCount > 5 ||
            fenxiData.thirdCount > 5 ||
            goujianData.firstCount > 5 ||
            goujianData.secondCount > 5 ||
            goujianData.thirdCount > 5 ||
            jianceData.firstCount > 5 ||
            jianceData.secondCount > 5 ||
            jianceData.thirdCount > 5
          ) {
            option.xAxis.max = null
          }

          this.finalState = jianceData.finalState;
          this.$nextTick(() => {
            var chartContainer = document.getElementById("overallHealthChart");
            var myChart = echarts.init(chartContainer);
            myChart.setOption(option);
          });
        }
      });
    },
    getScoreText(state) {
      return this.finalStateText[state];
    },
    getSssessmentComponent() {
      var that = this;
      var option = componentHealthChartOption;
      option.tooltip.formatter = function (params) {
        var seriesName = params.name;
        var value = params.value;
        var text = "<table>";
        value.forEach(function (val, index) {
          var indicatorName = option.radar.indicator[index].name;
          var scoreText = that.getScoreText(option.radar.indicator[index].state);
          text += "<tr>";
          text += "<td>" + indicatorName + "</td>";
          text += "<td> ： </td>";
          text += "<td>" + scoreText + "</td>";
          text += "</tr>";
        });
        text += "</table>";
        return seriesName + "<br>" + text;
      };
      var params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/component`, { params }).then((res) => {
        if (res.data.status == 200) {
          const resData = res.data.data;
          this.componentData = resData;
          var indicator = [];
          var valueData = [];
          for (var i = 0; i < resData.length; i++) {
            indicator.push({
              // name: resData[i].systemName.replace(/-/g, "\n"),
              name: resData[i].systemName,
              state: resData[i].state,
              textStyle: { fontSize: 12 },
              max: 3,
            });
            valueData.push(resData[i].score);
          }
          option.radar.indicator = indicator;
          option.radar.radius = "50%";
          option.series[0].data[0].value = valueData;
          var chartContainer = document.getElementById("componentHealthChart");
          var myChart = echarts.init(chartContainer);
          myChart.setOption(option);
        }
      });
    },
  },
};
</script>
      
      <style lang="scss" scoped>
.healthDegree-fragment {
  width: 100%;
  .fragment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .title-warp {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
      .title {
        color: #ffffff;
        font-size: 16px;
      }
    }
    .setting {
      display: flex;
      align-items: center;
      cursor: pointer;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
      .title {
        color: #32c5ff;
        font-size: 16px;
      }
    }
  }

  .fragment-content {
    display: flex;
    justify-content: space-between;
    .left-container {
      margin-left: 43px;
      .title {
        position: relative;
        font-size: 14px;
        color: #ffffff;
        span {
          display: inline-block;
          font-size: 14px;
          margin-left: 24px;
        }
      }
      .title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -22px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
      .echat-warp {
        display: flex;
        margin-top: 15px;
        position: relative;
        justify-content: space-between;
        left: -14px;
        .left-warp {
          margin-right: 20px;
          img {
            width: 124px;
            height: 124px;
          }
        }
        .overallHealthChart {
          width: 350px;
          height: 131px;
        }
        .item-component {
          display: flex;
        }
      }
    }
    .division {
      width: 0px;
      height: 162px;
      // background: rgb(169, 197, 216,0.45);
      margin: 0 26px 0 0px;
      position: relative;
      top: 4px;
      margin-left: 20px;
    }
    .right-container {
      .title {
        position: relative;
        font-size: 14px;
        color: #ffffff;
        span {
          display: inline-block;
          font-size: 14px;
          margin-left: 24px;
        }
      }
      .title::before {
        content: "";
        position: absolute;
        top: 4px;
        left: -22px;
        width: 4px;
        height: 12px;
        opacity: 0.58;
        background: #72d6f3;
      }
      .echat-warp {
        display: flex;
        margin-top: 14px;
        height: 148px;
        .left-warp {
          position: relative;
          width: 240px;
          height: 142px;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          overflow-y: auto;
          .item-component {
            display: flex;
            font-size: 14px;
            margin-bottom: 8px;
            .item-title {
              white-space: nowrap;
              color: #a7c8fc;
              margin-right: 12px;
              width: 120px;
              text-align: left;
            }
            .item-value {
              white-space: nowrap;
              text-align: left;
            }
          }
        }
        .right-warp {
          position: relative;
          left: 10px;
          top: -20px;
          .componentHealthChart {
            width: 348px;
            min-height: 159px;
          }
        }
      }
    }
  }
}
.right-slide-warp {
  position: absolute;
  right: 0;
  top: 40px;
  .top-slide-btn {
    background: #1b346e;
    cursor: pointer;
    transform: rotate(90deg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    // padding: 4px 2px;
  }
  .bottom-slide-btn {
    position: relative;
    top: 4px;
    background: #1b346e;
    cursor: pointer;
    transform: rotate(270deg);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
}
.scroll-transition {
  transition: scroll-behavior 0.5s ease-in-out; /* 调整动画持续时间和缓动函数 */
}
.component-warp {
  display: flex;
  flex-wrap: wrap;
  overflow-x: hidden;
  overflow-y: hidden;
  scroll-behavior: smooth;
  height: 142px;
}
</style>
      