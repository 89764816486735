<template>
  <div class="healthDegree-fragment">
    <div class="fragment-title">
      <div class="title-warp">
        <img src="@/assets/images/fragment-title-icon.png" />
        <div class="title">整体健康度信息</div>
      </div>
      <div class="level"></div>
    </div>
    <div class="fragment-content my-table">
        <el-table :data="tabledata" :border="true" :max-height="tableHeight" >
          <el-table-column header-align="center" align="center" prop="typeName" label="评定分类" min-width="86"> 
           
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="contentName" label="监测内容" min-width="107"> 
           
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="warnTypeName" label="报警分类" min-width="107"> </el-table-column>
          <el-table-column header-align="center" align="center" label="超限次数">
            <el-table-column header-align="center" align="center" prop="firstCount" label="一级" width="60"> </el-table-column>
            <el-table-column header-align="center" align="center" prop="secondCount" label="二级" width="60"> </el-table-column>
            <el-table-column header-align="center" align="center" prop="thirdCount" label="三级" width="60"> </el-table-column>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="level" label="监测内容 超限等级">
            <template slot-scope="scope">
              <div class="name">{{ levelList[scope.row.level-1] || "/" }}</div>
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>        
<script>
export default {
  props:['h'],
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      tabledata: [],
      levelList: ["一级", "二级", "三级"],
      tableHeight:0
    };
  },
  created() {
    this.tableHeight = this.h / 2 - 93;
  },
  mounted() {
    this.getAssessmentWholeList();
  },
  methods: {
    getAssessmentWholeList() {
      const params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/wholeList`, { params }).then((res) => {
        this.tabledata = res.data.data
      });
      // this.$axios.get(`${this.baseURL}health/assessment/componentList`, { params }).then((res) => {});
    },
  },
  watch:{
    h(newVal, oldVal) {
      this.tableHeight = newVal / 2 - 93
    }
  }
};
</script>
        
<style lang="scss" scoped>
.healthDegree-fragment {
  display: flex;
  flex-direction: column;
  width: 100%;

  .fragment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .title-warp {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
      .title {
        color: #ffffff;
        font-size: 16px;
      }
    }
    .level {
      display: flex;
    }
  }

  .fragment-content {

    padding-left: 42px;
    margin-right: 16px;
  }
}
::v-deep .my-table {
  height: calc(100% - 74px);
  margin-bottom: 20px;

  div.el-table {
    font-size: 12px;
  }
}
::v-deep .el-table--border, .el-table--group{
  border:1px solid rgb(59, 104, 169,0.3) !important;
}
::v-deep div.el-table th{
  background-color:transparent !important;
}
::v-deep  .el-table th.el-table__cell{
  border-right:1px solid rgb(59, 104, 169,0.3) !important;
  border-bottom:1px solid rgb(59, 104, 169,0.3) !important;
}
::v-deep .el-table__cell{
  border-right:1px solid rgb(59, 104, 169,0.3)!important;
  border-bottom:1px solid rgb(59, 104, 169,0.3)!important;
}
::v-deep .el-table--border::after, .el-table--group::after{
  width: 0;
}
</style>
        