<template>
  <div class="healthDegree-page">
    <div class="left-container">
      <div class="fragment-warp box">
        <assessFragment></assessFragment>
      </div>
      <div class="fragment-warp box"  style="margin-bottom: 0;">
        <trendFragment :h="viewportHeight"></trendFragment >
      </div>
    </div>
    <div class="right-container">
      <div class="fragment-warp box">
        <entiretyFragment :h="viewportHeight"></entiretyFragment>
      </div>
      <div class="fragment-warp box" style="margin-bottom: 0;">
        <componentFragment :h="viewportHeight"></componentFragment>
      </div>
    </div>
  </div>
</template>
    
<script>
import assessFragment from "./assessFragment.vue";
import trendFragment from "./trendFragment.vue";
import entiretyFragment from "./entiretyFragment.vue";
import componentFragment from "./componentFragment.vue";
export default {
  components: {
    assessFragment,
    trendFragment,
    entiretyFragment,
    componentFragment
  },
  data() {
    return {
      viewportHeight:0
    };
  },
  created() {
    this.getSize();
    window.addEventListener("resize", () =>  { 
      this.getSize();
    }, false);
  },
  methods:{
    getSize(){
        this.viewportHeight = window.innerHeight - 83 - 52 - 60
    }
  }
};
</script>
    
    <style lang="scss" scoped>
.healthDegree-page {
  display: flex;
  height: calc(100% - 20px);
  margin-top: 20px;
  .box{
    border: 1px solid #0C3380;
    background: #131f4673;
  }
  .left-container {
    display: flex;
    flex-direction: column;
    width: 64%;
    margin-right: 20px;
   >div:nth-child(2){
    flex: 1;
   }
  }
  .right-container {
    width:calc(36% - 20px);
    >div{
      height: calc(50% - 5px);
    }
  }
  .fragment-warp{
        margin-bottom: 10px;
    }
}
</style>
    