<template>
  <div class="healthDegree-fragment">
    <div class="fragment-title">
      <div class="title-warp">
        <img src="@/assets/images/fragment-title-icon.png" />
        <div class="title">构件健康度信息</div>
      </div>
      <div class="level"></div>
    </div>
    <div class="fragment-content my-table">
        <el-table :data="tabledata" :border="true" :max-height="tableHeight" >
          <el-table-column header-align="center" align="center" prop="systemName" label="系统" min-width="100"> </el-table-column>
          <el-table-column header-align="center" align="center" label="超限次数">
            <el-table-column header-align="center" align="center" prop="firstCount" label="一级" > </el-table-column>
            <el-table-column header-align="center" align="center" prop="secondCount" label="二级" > </el-table-column>
            <el-table-column header-align="center" align="center" prop="thirdCount" label="三级" > </el-table-column>
          </el-table-column>
          <el-table-column header-align="center" align="center" prop="level" label="健康度等级">
            <template slot-scope="scope">
              <div class="name">{{ levelList[scope.row.level] }}</div>
            </template>
          </el-table-column>
        </el-table>
    </div>
  </div>
</template>
        
<script>
export default {
  props:['h'],
  data() {
    return {
      projectId: sessionStorage.getItem("projectId"),
      tabledata: [],
      levelList:["Ⅰ基本完好", "Ⅱ轻微异常", "Ⅲ中等异常", "Ⅳ严重异常"],
      tableHeight:0
    };
  },
  created() {
    this.tableHeight = this.h / 2 - 93;
  },
  mounted() {
    this.getAssessmentWholeList();
  },
  methods: {
    getAssessmentWholeList() {
      const params = {
        projectId: this.projectId,
      };
      this.$axios.get(`${this.baseURL}health/assessment/componentList`, { params }).then((res) => {
        this.tabledata = res.data.data
      });
    },
  },
  watch:{
    h(newVal, oldVal) {
      this.tableHeight = newVal / 2 - 93
    }
  }
};
</script>
        
        <style lang="scss" scoped>
.healthDegree-fragment {
  width: 100%;
  .fragment-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    .title-warp {
      display: flex;
      align-items: center;
      img {
        width: 14px;
        height: 14px;
        margin-right: 12px;
      }
      .title {
        color: #ffffff;
        font-size: 16px;
      }
    }
    .level {
      display: flex;
    }
  }

  .fragment-content {
    display: flex;
    padding-left: 42px;
    margin-right: 16px;
  }
}
::v-deep .my-table {
  margin-bottom: 20px;
  >div{
    width: 100%;
  }
  div.el-table {
    font-size: 12px;
  }
}
::v-deep .el-table--border, .el-table--group{
  border:1px solid rgb(59, 104, 169,0.3) !important;
}
::v-deep div.el-table th{
  background-color:transparent !important;
}
::v-deep  .el-table th.el-table__cell{
  border-right:1px solid rgb(59, 104, 169,0.3) !important;
  border-bottom:1px solid rgb(59, 104, 169,0.3) !important;
}
::v-deep .el-table__cell{
  border-right:1px solid rgb(59, 104, 169,0.3)!important;
  border-bottom:1px solid rgb(59, 104, 169,0.3)!important;
}
::v-deep .el-table--border::after, .el-table--group::after{
  width: 0;
}
</style>
        