export const overallHealthChartOption = {
  tooltip: {},
  grid: {
    top: 25,
    left: 78,
    bottom: 20,
    right: 53,
  },
  legend: {
    data: ["一级", "二级", "三级"],
    textStyle: {
      fontSize: 12,
      color: "#A7C8FC",
    },
    itemWidth: 8,
    itemHeight: 8,
    itemGap: 18,
    padding: [5, 0, 0, 0],
  },
  color: ["#1890FF", "#FFC034", "#FF7A39"],
  xAxis: {
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
      formatter: function (value) {
        return value + "";
      }
    },
    minInterval: 1,
    min: 0,
    max: 5,
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: "rgba(169, 197, 216, 0.6)",
      },
      z: 99
    },
    name: "（次）",
    nameTextStyle: {
      color: "#A7C8FC", // 单位颜色
      fontSize: 14, // 单位字体大小
      padding: [0, 0, -28, 0], // 单位与轴线的间距，可根据需要调整
    },
  },
  yAxis: {
    type: "category",
    data: ["构件评估", "分析结果", "监测数据"],
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
    },
    axisTick: {
      show: false,
    },
    axisLine: {
      show: false,
    },
  },
  series: [
    {
      name: "一级",
      type: "bar",
      data: [],
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '-20%',
      barWidth: 4
    },
    {
      name: "二级",
      type: "bar",
      data: [],
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '-20%',
      barWidth: 4
    },
    {
      name: "三级",
      type: "bar",
      data: [],
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '-20%',
      barWidth: 4
    },
  ],
};

export const componentHealthChartOption = {
  grid: {
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
  },
  tooltip: {
    position: 'bottom',
  },
  radar: {
    radius: '55%',
    // 雷达图的指示器
    indicator: [],
    splitLine: {
      // 背景线的颜色设置
      lineStyle: {
        color: ["#A9C5D8"], // 可以设置为单一颜色或数组，表示分隔线的颜色
      },
    },
    splitArea: {
      show: false, // 设置为 false，表示不显示背景区域
    },
  },
  series: [
    {
      type: "radar",
      // 数据
      data: [
        {
          value: [],
          name: "构件健康度",
          lineStyle: {
            color: "#1AC7FD", // 数据线的颜色
            width: 2,
          },
          itemStyle: {
            color: "rgb(0,0,0)",
            borderColor: "#1AC7FD",
            borderWidth: 2,
          },
          symbolSize: 8,
        },
      ],
      areaStyle: {
        color: "rgba(0, 148, 255, 0.21)", // 设置折线区域颜色
      },
    },
  ],
};


export const assessmentHistoryChartOption = {
  grid: {
    top: 30,
    left: 40,
    bottom: 25,
    right: 0,
  },
  tooltip: {
    formatter: function (params) {
      console.log("dada",params);
      return `${params.seriesName}:${params.value}`;
    },
  },
  legend: {
    data: ["监测数据", "分析结果", '构件评估'],
    textStyle: {
      fontSize: 12,
      color: "#A7C8FC",
    },
    itemWidth: 14,
    itemHeight: 8,
    itemGap: 28,
  },
  color: ["#1890FF", "#39F9B1", "#BFF219"],
  xAxis: {
    type: "category",
    data: [],
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216,0.6)",
      },
    },
  },
  yAxis: {
    type: "value",
    minInterval: 1,
    min: 0,
    max: 4,
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216, 0.6)",
      },
    },
    splitLine: {
      show: true,
      lineStyle: {
        type: "dashed",
        color: "rgba(169, 197, 216, 0.6)",
      },
    },
  },
  series: [
    {
      name: "监测数据",
      type: "bar",
      data: [],
      barMaxWidth: 30,
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '0',
      barWidth: '10px'
    },
    {
      name: "分析结果",
      type: "bar",
      data: [],
      barMaxWidth: 30,
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '0',
      barWidth: '10px'
    },
    {
      name: "构件评估",
      type: "bar",
      data: [],
      barMaxWidth: 30,
      showBackground: true,
      backgroundStyle: {
        color: 'rgba(33,59,121,0.25)'
      },
      barGap: '0',
      barWidth: '10px'
    },
  ],
};

export const assessmentWholeChartOption = {
  animation: false,
  tooltip: {
  },
  grid: {
    top: 10,
    left: 25,
    bottom: 5,
    right: 90,
  },
  xAxis: {
    type: 'time',
    data: [],
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
      interval: 0,
      show: false,
      formatter: function (value, index) {
        const date = new Date(value);
        const month = date.getMonth() + 1;
        return month + '月';
      },
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216,0.6)",
      },
    },
    splitLine: {
      show: false,  // 隐藏分隔线
    },
  },
  yAxis: [{
    type: "category", // 使用类目轴
    data: ["IV严重异常", "III中等异常", "II轻微异常", "I基本完好"],
    boundaryGap: false,
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
      verticalAlign: 'middle',
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      show: false, // 隐藏轴线
      lineStyle: {
        color: "#A9C5D8",
      },
    },
    splitLine: {
      show: true, // 显示分隔线
      lineStyle: {
        type: "dashed", // 设置虚线
        color: "#A9C5D8",
      },
    },
    position: "right",
  }, {
    type: "category", // 使用类目轴
    data: [],
    boundaryGap: false,
    axisLabel: {
      fontSize: 14,
      color: "#A7C8FC",
      offset: 100,
      verticalAlign: 'middle',

    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216,0.6)",
      },
    },
    splitLine: {
      show: true, // 显示分隔线
      lineStyle: {
        type: "dashed", // 设置虚线
        color: "#A9C5D8",
      },
    },
    position: "left",
  }],
  series: [
    {
      name: "状态",
      type: "line",
      data: [],
      lineStyle: {
        color: "#1BC8C0",
      },
      itemStyle: {
        color: "#1BC8C0",
      },
    },
  ],

};

export const assessmentComponentChartOption = {
  animation: false,
  grid: {
    top: 15,
    left: 85,
    bottom: 5,
    right: 40,
  },
  tooltip: {
    formatter: function (params) {
      let data = ["IV严重异常", "III中等异常", "II轻微异常", "I基本完好"];
      return "时间: " + params.name + "<br/>健康度: " + data[params.value];
    },
  },
  xAxis: {
    type: 'time',
    data: [],
    valueData: [],
    axisLabel: {
      fontSize: 12,
      color: "#A7C8FC",
      interval: 0,
      show: false,
      formatter: function (value, index) {
        const date = new Date(value);
        const month = date.getMonth() + 1;
        return month + '月';
      },
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216,0.6)",
      },
    },
    splitLine: {
      show: false,  // 隐藏分隔线
    },
  },
  yAxis: [{
    type: "category", // 使用类目轴
    data: ["IV严重异常", "III中等异常", "II轻微异常", "I基本完好"],
    boundaryGap: false,
    axisLabel: {
      fontSize: 12,
      color: "#A7C8FC",
      verticalAlign: "top",
      position: "right",
      padding: [-5, 0, 0, 0]
    },
    axisTick: {
      show: false, // 隐藏刻度
    },
    axisLine: {
      lineStyle: {
        color: "rgba(169, 197, 216,0.6)",
      },
    },
    splitLine: {
      show: true, // 显示分隔线
      lineStyle: {
        type: "dashed", // 设置虚线
        color: "#A9C5D8",
      },
    },
  }],
  series: [
    {
      name: "状态",
      type: "line",
      data: [],
      lineStyle: {
        color: "#C5BC4C",
      },
      itemStyle: {
        color: "#C5BC4C",
      },
    },
  ],
};